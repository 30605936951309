/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

body, html {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.spinner {
  width: 4.14rem;
  height: 4.14rem;
  border: 4px solid #dcdcdc;
  border-top: 4px solid #1f1f1f;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  margin-top: 1em;
  font-size: 1.5em;
  color: #333;
}
